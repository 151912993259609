import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function NurseryAdmissions() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya | Nursery Admissions"
        description="Admissions to Nursery are regulated in accordance with the guidelines issued by the Directorate of Education. View admission criteria, classification of seats and schedules & timelines."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="nurseryadmission">
        <h1 className="heading">Nursery Admissions</h1>

        <p className="nurseryadmission__text">
          Admissions to Nursery are regulated in accordance with the guidelines
          issued by the Directorate of Education. Admission criteria,
          classification of seats and schedules & timelines are available below,
          as well as on the school noticeboard. <br />
          <br /> Please note that registration is a request for admission, not a
          guarantee of selection.
        </p>
        <br></br>

        {/* <center style={{ padding: "10px 40px" }}>
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/2nd-Waiting-List-06-Feb-2023.pdf"
            target="_blank"
          >
            NURSERY ADMISSION 2023 - 2024 - Second Waiting List
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/First-Selected-List-23.pdf"
            target="_blank"
          >
            NURSERY ADMISSION 2023 - 2024 - First Selected List
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/Nursery-waiting-list-65%20pointer.pdf"
            target="_blank"
          >
            NURSERY ADMISSION 2023 - 2024 - Waiting List - 65 Pointers
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/Waiting-List-23.pdf"
            target="_blank"
          >
            NURSERY ADMISSION 2023 - 2024 - Waiting List
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/NURSERY-LIST-2023-23.pdf"
            target="_blank"
          >
            NURSERY ADMISSION 2023 - 2024 - Registered Candidates
          </a>
        </center>
      
        <p style={{ fontWeight: "bold", textAlign: "center" }}>
          Use of desktop or laptop is recommended to fill in the registration
          form.
        </p>
        <br />*/}

        <center>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/WAITING-2nd-LIST-211-DT.29-JAN-2024-with-letter.pdf"
            target="_blank"
          >
            2nd Waiting List - Pre-school (Nursery) Admission 2024-25
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/selected-list-pre-school-2024-with-letter.pdf"
            target="_blank"
          >
            Selected List - Pre-school (Nursery) Admission 2024-25
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/waiting-list-pre-school-2024-with-letter.pdf"
            target="_blank"
          >
            Waiting List - Pre-school (Nursery) Admission 2024-25
          </a>
          <br />
          <br />
          <a
            className="btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/TOTAL-REGISTERED-APPLICANTS-FOR-PRE-SCHOOL-ADMISSION.pdf"
            target="_blank"
          >
            TOTAL REGISTERED APPLICANTS FOR PRE-SCHOOL ADMISSION WITH POINT
            CRITERIA
          </a>
        </center>
        <video
          src="https://chinmayavvdelhi.ac.in/assets/admission-video-2023.mp4"
          controls
        />

        {/* <iframe
          src="https://www.youtube.com/embed/iH9IOSX-7x0?si=CReKoK7ztk6mySQP"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
        <center>
          {/* <a
            target="_blank"
            href="https://cvvvar.iwsportal.com:572/"
            className="btn-dark"
          >
            Click Here For Registration
          </a> */}
          <div className="tel">
            FOR ADMISSION ENQUIRY CALL -{" "}
            <a href="tel:9999834424">
              <strong>9999834424</strong>
            </a>
          </div>
        </center>

        {/* <div className="nurseryadmission__buttons">
          <a
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/nursery-for-the-website-9th-March2021.pdf"
            className="nurseryadmission__buttons__btn"
          >
            List of Registrated Students (General Category)
          </a>
          <br />

          <a
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/Nursery-15thMarch2021.pdf"
            className="nurseryadmission__buttons__btn"
          >
            Points Details Of All Registered Candidates For Nursery Admission
            (General Category)
          </a>
          <br />
          <a
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/FIRST-SELECTED-LIST-FOR-NURSERY-GENERAL-ADMISSION-2021-22.pdf"
            className="nurseryadmission__buttons__btn"
          >
            First Selected List For Nursery General Category Admission (2021-22)
          </a>

          <a
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/SECOND-SELECTED-LIST-FOR-NURSERY-25TH-MARCH-2021.pdf"
            className="nurseryadmission__buttons__btn"
          >
            Second Selected List For Nursery General Admission
          </a>
        </div> */}

        <div className="nurseryadmission__section2">
          <h2 className="heading">Schedule 2024 - 25</h2>
          <a
            target="_blank"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/Nursery-Schedule-for-Entry-Level-Classes.pdf"
            className="btn-dark"
            download="schedule-2024-2025.pdf"
          >
            Download <i className="fas fa-file-download"></i>
          </a>
        </div>

        <div className="nurseryadmission__section3">
          <h2 className="heading--white">Admission Criteria</h2>
          <a
            target="_blank"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/Nursery-Admission-Criteria-2024-25.pdf"
            className="btn-white"
            download
          >
            Criteria For Nursery Admissions{" "}
            <i className="fas fa-file-download"></i>
          </a>
          <a
            target="_blank"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/Nursery-Schedule-for-Entry-Level-Classes.pdf"
            className="btn-white"
            download
          >
            CBSE Circular For Nursery Admission Criteria{" "}
            <i className="fas fa-file-download"></i>
          </a>

          <div className="nurseryadmission__section3__container">
            <div className="row">
              <div className="col col-12">
                <div className="nurseryadmission__section3__lefttable">
                  <h4 className="">Age Eligibilty</h4>
                  {/* <div className="nurseryadmission__table">
                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        Minimum age as on March 31, 2021
                      </div>
                      <div className="nurseryadmission__table__col2">
                        3 years
                      </div>
                    </div>

                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        Maximum age as on March 31, 2021
                      </div>
                      <div className="nurseryadmission__table__col2">
                        Less than 4 years
                      </div>
                    </div>
                  </div> */}
                  Children born between 01.04.2020 & 31.03.2021 will be eligible
                  for admission to Pre-School (Nursery) class.
                  <br />
                  <br />
                </div>
              </div>
              <div className="col col-12">
                <div className="nurseryadmission__section3__lefttable">
                  <h4 className="">Availability Of Seats</h4>

                  <div className="nurseryadmission__table">
                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        <strong>Category</strong>
                      </div>
                      <div className="nurseryadmission__table__col2">
                        <strong>Seats Available</strong>
                      </div>
                    </div>

                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        Total No. of Seats
                      </div>
                      <div className="nurseryadmission__table__col2">160</div>
                    </div>

                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        Economically Weaker Section & Disadvantaged Group (25%)
                      </div>
                      <div className="nurseryadmission__table__col2">40</div>
                    </div>

                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        Management Quota (20%)
                      </div>
                      <div className="nurseryadmission__table__col2">32</div>
                    </div>

                    <div className="nurseryadmission__table__row">
                      <div className="nurseryadmission__table__col1">
                        General Seats
                      </div>
                      <div className="nurseryadmission__table__col2">88</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="nurseryadmission__section4">
          <h2 className="heading">EWS/DG/CWSN Category</h2>

          <p className="nurseryadmission__section4__text">
            The admission under EWS/DG (including CWSN) category for 25% of the
            total seats are done by Directorate of Education (DOE), Government
            of Delhi No application for CWSN is accepted by hand/post/e-mail or
            any other means.
          </p>

          <h2 className="heading">General Category</h2>

          <p className="nurseryadmission__section4__text">
            The criteria for selection of these children are in accordance with
            the guidelines given by DOE i.e. there is no interviewing or
            screening of children or their parents. The whole process is fair
            and rational, non-discriminatory, transparent and child-friendly.
            Admission is based on the information given and should be backed by
            authentic proofs. Information regarding parents' professions and
            educational qualifications does not form a part of the admission
            criteria. It is used only for school data processing. Admission for
            the General Category of seats are based on the following criteria as
            per the school policy for which points are given to ensure a
            rational and fair selection. If there is a tie in the points for the
            seats to be allotted there will be Random Selection in the form of a
            Draw of lots to finalize these seats. The list of selected and
            non-selected candidates are placed on the school website showing the
            points secured as per guidelines given by DOE.
          </p>
          <br />

          <h4>Tier Point For Admission Of Open Seats (2024-25)</h4>
          <div className="nurseryadmission__table">
            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">
                Neighbourhood 0 - 15 km
              </div>
              <div className="nurseryadmission__table__col2">50</div>
            </div>

            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">
                Neighbourhood {">"} 15 km
              </div>
              <div className="nurseryadmission__table__col2">45</div>
            </div>

            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">
                Sibling Real brother / sister studying in Chinmaya Vidyalaya,
                Vasant Vihar
              </div>
              <div className="nurseryadmission__table__col2">20</div>
            </div>

            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">
                Alumni Father / Mother having passed class XII from Chinmaya
                Vidyalaya, Vasant Vihar
              </div>
              <div className="nurseryadmission__table__col2">05</div>
            </div>

            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">Girl Child</div>
              <div className="nurseryadmission__table__col2">05</div>
            </div>

            <div className="nurseryadmission__table__row">
              <div className="nurseryadmission__table__col1">First Child</div>
              <div className="nurseryadmission__table__col2">20</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
